import { useEffect, useRef, useState } from 'react'
// ** Next Import
import { Link, useLocation, useNavigate } from 'react-router-dom'

// ** MUI Imports
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CruzLogo from 'src/@core/components/icons/CruzLogo'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Drawer from '@mui/material/Drawer'
import Divider from '@mui/material/Divider'
import Collapse from '@mui/material/Collapse'
import LogoutIcon from '@mui/icons-material/Logout'

import { styled, useTheme } from '@mui/material/styles'
import NotificationIcon from 'src/@core/components/icons/notificationIcon'
import EarthIcon from 'src/@core/components/icons/earthIcon'
import ArrowDown from 'src/@core/components/icons/arrowDown'
import Paaji from 'src/assets/images/paaji.svg'

// MUI Icons Imports
import MenuIcon from '@mui/icons-material/Menu'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import MailIcon from '@mui/icons-material/Mail'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import StarBorder from '@mui/icons-material/StarBorder'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { useAuth } from 'src/hooks/useAuth'
import DailogAuth from 'src/components/auth/DailogAuth'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

// ** Configs
const AppBarWrapper = styled(AppBar)(({ scroll, location }) => ({
  backgroundColor: location.pathname === '/landing-page' ? (scroll ? '#FFFFFF' : 'transparent') : '#FFFFFF',
  transition: 'background-color 0.3s ease-in-out'
}))

const LoginButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.tertiary.main,
  color: theme.palette.tertiary.contrastText,
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px'
}))

const RegisterButton = styled(Button)(({ theme, scroll, location }) => ({
  border:
    location.pathname === '/landing-page'
      ? scroll
        ? `1px solid ${theme.palette.secondary.main}`
        : `1px solid ${theme.palette.tertiary.main}`
      : `1px solid ${theme.palette.secondary.main}`,
  color:
    location.pathname === '/landing-page'
      ? scroll
        ? theme.palette.secondary.main
        : theme.palette.tertiary.main
      : theme.palette.secondary.main,
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px'
}))

const UserAvatar = styled(Avatar)(({ theme }) => ({
  cursor: 'pointer',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  width: '38px',
  height: '38px'
}))
const UserName = styled('span')(({ theme }) => ({
  marginRight: theme.spacing(1),
  color: theme.palette.primary.contrastText
}))

const NotificationAvatar = styled(Avatar)(({ theme, scroll, location }) => ({
  borderRadius: '200px',
  border:
    location.pathname === '/landing-page'
      ? scroll
        ? `1px solid ${theme.palette.secondary.main}`
        : `1px solid ${theme.palette.tertiary.main}`
      : `1px solid ${theme.palette.secondary.main}`,
  backgroundColor: 'transparent'
}))

const ListTextTypo = styled(ListItemText)(({ theme, scroll, location }) => ({
  '& .MuiListItemText-primary': {
    fontSize: '16px',
    color:
      location.pathname === '/landing-page'
        ? scroll
          ? theme.palette.secondary.main
          : '#FFFFFF'
        : theme.palette.secondary.main,
    fontWeight: 600
  }
}))

const HomeTextTypo = styled(ListItemText)(({ theme, scroll, location }) => ({
  '& .MuiListItemText-primary': {
    fontSize: '16px',
    color: location.pathname === '/landing-page' ? theme.palette.secondary.main : theme.palette.secondary.main,
    fontWeight: 600
  }
}))

const DrawerHeaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start'
}))

const menuItems = [
  'Top Boat Rental Cities',
  'Lake Boat Rentals',
  'Boat Rental Near Me',
  'Jet Ski Rental Near Me',
  'Fishing Charters Near Me',
  'Yacht Rentals Near Me'
]

const PublicLayoutAppBar = () => {
  // ** Hooks
  const theme = useTheme()
  const location = useLocation()
  const navigate = useNavigate()
  const { user, logout } = useAuth()
  const authRef = useRef()

  const [scroll, setScroll] = useState(false)
  const [anchorElUser, setAnchorElUser] = useState(null)

  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElNotification, setAnchorElNotification] = useState(null)
  const [anchorElBrowse, setAnchorElBrowse] = useState(null)
  const [openDropdown, setOpenDropdown] = useState(false)

  const [openDrawer, setOpenDrawer] = useState(false)
  const [openBrowseCollapse, setOpenBrowseCollapse] = useState(false)

  const userName = user ? user.firstName : ''
  const userInitial = userName ? userName.charAt(0).toUpperCase() : ''

  const handleClickBrowseCollapse = () => {
    setOpenBrowseCollapse(!openBrowseCollapse)
  }

  const handleDrawerOpen = () => {
    setOpenDrawer(true)
  }

  const handleDrawerClose = () => {
    setOpenDrawer(false)
  }

  const handleOpenUserMenu = event => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }
  const handleLogout = () => {
    logout()
    handleCloseUserMenu()
    setOpenDrawer(false)
  }

  const open = Boolean(anchorEl)
  const openNoti = Boolean(anchorEl)

  const handleOpenLanguageDropDown = event => {
    console.log('event', event)
    setAnchorEl(event.currentTarget)
  }

  const handleCloseLanguageDropDown = () => {
    setAnchorEl(null)
  }

  const handleOpenNotificationDropDown = event => {
    console.log('event', event)
    setAnchorElNotification(event.currentTarget)
  }

  const handleCloseNotificationDropDown = () => {
    setAnchorElNotification(null)
  }

  const handleOpenBrowseDropDown = event => {
    console.log('event', event)
    setAnchorElBrowse(event.currentTarget)
  }

  const handleCloseBrowseDropDown = () => {
    setAnchorElBrowse(null)
  }

  const handleNavigate = path => {
    navigate(path)
    setOpenDrawer(false)
  }
  const handleClick = () => {
    setOpenDropdown(!openDropdown)
  }

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY > 0)
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <AppBarWrapper
      elevation={0}
      scroll={scroll}
      location={location}
      position={location.pathname === '/landing-page' ? 'fixed' : 'sticky'}
      sx={{ top: 0 }}
    >
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          marginInline: { xs: '1rem', sm: '1rem', md: '5rem' },
          minHeight: '90px'
        }}
      >
        <Box display={'flex'} flexGrow={1}>
          <Box flexGrow={1} display={'flex'} alignItems={'center'}>
            <Link to='/landing-page' style={{ display: 'flex' }}>
              <CruzLogo
                width='130'
                height='50'
                fill={location.pathname === '/landing-page' ? (scroll ? '#0D1E32' : '#FFFFFF') : '#0D1E32'}
              />
            </Link>
          </Box>
          <Box
            display={{ xs: 'none', sm: 'none', md: 'flex' }}
            alignItems={'center'}
            justifyContent={'space-between'}
            gap={2}
          >
            <List>
              <ListItem
                sx={{
                  gap: 2,
                  justifyContent: 'space-between',
                  color: theme.palette.text.primary,
                  paddingX: 0
                }}
              >
                <ListItemButton
                  sx={{
                    backgroundColor: 'primary.main',
                    borderRadius: '500px',
                    paddingBlock: '5px',
                    '&:hover': {
                      backgroundColor: 'primary.main'
                    }
                  }}
                  onClick={() => navigate('/landing-page')}
                >
                  <HomeTextTypo primary={'Home'} scroll={scroll} location={location} />
                </ListItemButton>
                <ListItemButton
                  onClick={e => {
                    if (open) {
                      handleCloseBrowseDropDown()
                    } else {
                      handleOpenBrowseDropDown(e)
                    }
                  }}
                  sx={{
                    borderRadius: '500px',
                    paddingBlock: '5px',
                    gap: '5px'
                  }}
                >
                  <ListTextTypo primary={'Browse'} scroll={scroll} location={location} />
                  <ArrowDown
                    fill={location.pathname === '/landing-page' ? (scroll ? '#0D1E32' : '#B5D9D7') : '#0D1E32'}
                  />
                </ListItemButton>
                <Menu
                  anchorEl={anchorElBrowse}
                  open={Boolean(anchorElBrowse)}
                  onClose={handleCloseBrowseDropDown}
                  sx={{
                    mt: 2,
                    '& .MuiMenu-paper': { backgroundColor: '#FFFFFF', padding: '8px', borderRadius: '10px' }
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                  }}
                >
                  <Box display={'flex'} flexDirection={'column'} gap={1}>
                    {menuItems.map((item, index) => (
                      <MenuItem
                        key={index}
                        disableRipple
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: '12px 16px',
                          borderBottom: '1px solid #E7E9EB',
                          gap: 10
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '16px',
                            color: '#000',
                            fontWeight: 500,
                            lineHeight: '20px'
                          }}
                        >
                          {item}
                        </Typography>
                        <ArrowForwardIosIcon sx={{ fontSize: '16px', color: '#7B7B7B' }} />
                      </MenuItem>
                    ))}
                  </Box>
                </Menu>

                <ListItemButton
                  sx={{
                    borderRadius: '500px',
                    paddingBlock: '5px'
                  }}
                >
                  <ListTextTypo primary={'Help'} scroll={scroll} location={location} />
                </ListItemButton>
                <ListItemButton
                  onClick={e => {
                    if (anchorEl !== null) {
                      handleCloseLanguageDropDown()
                    } else {
                      handleOpenLanguageDropDown(e)
                    }
                  }}
                  sx={{
                    borderRadius: '500px',
                    paddingBlock: '5px',
                    gap: '5px'
                  }}
                >
                  <EarthIcon
                    fill={location.pathname === '/landing-page' ? (scroll ? '#0D1E32' : '#B5D9D7') : '#0D1E32'}
                  />
                  <ListTextTypo primary={'English'} scroll={scroll} location={location} />
                  <ArrowDown
                    fill={location.pathname === '/landing-page' ? (scroll ? '#0D1E32' : '#B5D9D7') : '#0D1E32'}
                  />
                </ListItemButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => handleCloseLanguageDropDown()}
                  sx={{
                    '& .MuiMenu-paper': { width: 160, padding: '10px 20px', borderRadius: '10px' }
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                  }}
                >
                  <Box display={'flex'} flexDirection={'column'} gap={2}>
                    <MenuItem
                      sx={{
                        width: '100px',
                        borderRadius: '500px',
                        backgroundColor: 'primary.main',
                        fontSize: '16px',
                        color: 'secondary.main',
                        lineHeight: '24px',
                        fontWeight: 400,
                        '&:hover': { backgroundColor: 'primary.main' }
                      }}
                      onClick={() => handleCloseLanguageDropDown()}
                    >
                      English
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleCloseLanguageDropDown()}
                      sx={{
                        borderRadius: '500px',
                        width: '100px',
                        fontSize: '16px',
                        color: 'secondary.main',
                        fontWeight: 400,
                        lineHeight: '24px'
                      }}
                    >
                      French
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleCloseLanguageDropDown()}
                      sx={{
                        borderRadius: '500px',
                        width: '100px',
                        fontSize: '16px',
                        color: 'secondary.main',
                        fontWeight: 400,
                        lineHeight: '24px'
                      }}
                    >
                      Arabic
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleCloseLanguageDropDown()}
                      sx={{
                        borderRadius: '500px',
                        width: '100px',
                        fontSize: '16px',
                        color: 'secondary.main',
                        fontWeight: 400,
                        lineHeight: '24px'
                      }}
                    >
                      Spanish
                    </MenuItem>
                  </Box>
                </Menu>
              </ListItem>
            </List>
            <IconButton
              sx={{ p: 0 }}
              onClick={e => {
                if (open) {
                  handleCloseNotificationDropDown()
                } else {
                  handleOpenNotificationDropDown(e)
                }
              }}
            >
              <NotificationAvatar alt='Notification Icon' scroll={scroll} location={location}>
                <NotificationIcon
                  fill={location.pathname === '/landing-page' ? (scroll ? '#0D1E32' : '#B5D9D7') : '#0D1E32'}
                  size={'21'}
                />
              </NotificationAvatar>
            </IconButton>
            <Menu
              anchorEl={anchorElNotification}
              open={Boolean(anchorElNotification)}
              onClose={() => handleCloseNotificationDropDown()}
              sx={{
                mt: 2,
                '& .MuiMenu-paper': { backgroundColor: '#FFFFFF', borderRadius: '10px' }
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <Box display={'flex'} flexDirection={'column'} gap={2}>
                <MenuItem disableRipple>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <Typography
                      sx={{ fontSize: '18px', color: 'secondary.main', fontWeight: 700, lineHeight: '21.6px' }}
                    >
                      Notifications
                    </Typography>
                  </Box>
                </MenuItem>
                {[0, 1, 2, 3, 4, 5].map((item, index) => {
                  return (
                    <Card sx={{ py: 1 }}>
                      <MenuItem as={Card} disableRipple onClick={handleCloseNotificationDropDown}>
                        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                          <Avatar alt='Flora' src={Paaji} />
                          <Box
                            sx={{ mx: 4, flex: '1 1', display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                          >
                            <Typography
                              noWrap
                              sx={{ fontSize: '14px', color: 'secondary.main', lineHeight: '20px', fontWeight: 600 }}
                            >
                              Jack changed his username.
                            </Typography>
                          </Box>
                          <Typography sx={{ fontSize: '12px', fontWeight: 500, lineHeight: '16px', color: '#7B7B7B' }}>
                            2 min
                          </Typography>
                        </Box>
                      </MenuItem>
                    </Card>
                  )
                })}
              </Box>
            </Menu>
            {user ? (
              <Box
                display={{ xs: 'none', sm: 'none', md: 'flex' }}
                alignItems={'center'}
                justifyContent={'space-between'}
                gap={2}
              >
                <Button
                  sx={{ bgcolor: '#C1D7D6', padding: '4px 10px', ':hover': { bgcolor: '#C1D7D6' } }}
                  onClick={handleOpenUserMenu}
                  startIcon={<UserAvatar>{userInitial}</UserAvatar>}
                  endIcon={<ArrowDropDownIcon sx={{ color: '#000' }} />}
                >
                  <UserName>{userName}</UserName>
                </Button>
                <Menu
                  anchorEl={anchorElUser}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                  sx={{
                    mt: '47px',
                    '& .MuiMenu-paper': { borderRadius: '10px', width: '110px' }
                  }}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                >
                  <MenuItem sx={{ fontSize: '18px', color: '#0D1E32' }} onClick={handleCloseUserMenu}>
                    Profile
                  </MenuItem>
                  <MenuItem sx={{ fontSize: '18px', color: '#0D1E32' }} onClick={() => navigate('/bookings')}>
                    Bookings
                  </MenuItem>
                  <MenuItem sx={{ fontSize: '18px', color: '#0D1E32' }} onClick={handleLogout}>
                    Logout
                  </MenuItem>
                </Menu>
              </Box>
            ) : (
              <>
                <RegisterButton
                  variant='outlined'
                  onClick={() => {
                    authRef?.current?.openRegistartion()
                  }}
                  scroll={scroll}
                  location={location}
                >
                  Register
                </RegisterButton>
                <LoginButton
                  variant='contained'
                  onClick={() => {
                    authRef?.current?.openLogin()
                  }}
                >
                  Login
                </LoginButton>
              </>
            )}
          </Box>
          <Box display={{ xs: 'flex', sm: 'flex', md: 'none' }} alignItems={'center'}>
            <IconButton
              onClick={e => {
                if (anchorEl !== null) {
                  handleCloseLanguageDropDown()
                } else {
                  handleOpenLanguageDropDown(e)
                }
              }}
              sx={{
                borderRadius: '500px',
                paddingBlock: '5px',
                gap: '5px'
              }}
            >
              <NotificationAvatar alt='Notification Icon' scroll={scroll} location={location}>
                <EarthIcon
                  fill={location.pathname === '/landing-page' ? (scroll ? '#0D1E32' : '#B5D9D7') : '#0D1E32'}
                />
              </NotificationAvatar>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => handleCloseLanguageDropDown()}
              sx={{
                '& .MuiMenu-paper': { width: 160, padding: '10px 20px', borderRadius: '10px' }
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <Box display={'flex'} flexDirection={'column'} gap={2}>
                <MenuItem
                  sx={{
                    width: '100px',
                    borderRadius: '500px',
                    backgroundColor: 'primary.main',
                    fontSize: '16px',
                    color: 'secondary.main',
                    lineHeight: '24px',
                    fontWeight: 400,
                    '&:hover': { backgroundColor: 'primary.main' }
                  }}
                  onClick={() => handleCloseLanguageDropDown()}
                >
                  English
                </MenuItem>
                <MenuItem
                  onClick={() => handleCloseLanguageDropDown()}
                  sx={{
                    borderRadius: '500px',
                    width: '100px',
                    fontSize: '16px',
                    color: 'secondary.main',
                    fontWeight: 400,
                    lineHeight: '24px'
                  }}
                >
                  French
                </MenuItem>
                <MenuItem
                  onClick={() => handleCloseLanguageDropDown()}
                  sx={{
                    borderRadius: '500px',
                    width: '100px',
                    fontSize: '16px',
                    color: 'secondary.main',
                    fontWeight: 400,
                    lineHeight: '24px'
                  }}
                >
                  Arabic
                </MenuItem>
                <MenuItem
                  onClick={() => handleCloseLanguageDropDown()}
                  sx={{
                    borderRadius: '500px',
                    width: '100px',
                    fontSize: '16px',
                    color: 'secondary.main',
                    fontWeight: 400,
                    lineHeight: '24px'
                  }}
                >
                  Spanish
                </MenuItem>
              </Box>
            </Menu>
            <IconButton
              sx={{ p: 0 }}
              onClick={e => {
                if (open) {
                  handleCloseNotificationDropDown()
                } else {
                  handleOpenNotificationDropDown(e)
                }
              }}
            >
              <NotificationAvatar alt='Notification Icon' scroll={scroll} location={location}>
                <NotificationIcon
                  fill={location.pathname === '/landing-page' ? (scroll ? '#0D1E32' : '#B5D9D7') : '#0D1E32'}
                  size={'21'}
                />
              </NotificationAvatar>
            </IconButton>
            <Menu
              anchorEl={anchorElNotification}
              open={Boolean(anchorElNotification)}
              onClose={() => handleCloseNotificationDropDown()}
              sx={{
                mt: 2,
                '& .MuiMenu-paper': { backgroundColor: '#FFFFFF', borderRadius: '10px' }
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <Box display={'flex'} flexDirection={'column'} gap={2}>
                <MenuItem disableRipple>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <Typography
                      sx={{ fontSize: '18px', color: 'secondary.main', fontWeight: 700, lineHeight: '21.6px' }}
                    >
                      Notifications
                    </Typography>
                  </Box>
                </MenuItem>
                {[0, 1, 2, 3, 4, 5].map((item, index) => {
                  return (
                    <Card sx={{ py: 1 }}>
                      <MenuItem as={Card} disableRipple onClick={handleCloseNotificationDropDown}>
                        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                          <Avatar alt='Flora' src={Paaji} />
                          <Box
                            sx={{ mx: 4, flex: '1 1', display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                          >
                            <Typography
                              noWrap
                              sx={{ fontSize: '14px', color: 'secondary.main', lineHeight: '20px', fontWeight: 600 }}
                            >
                              Jack changed his username.
                            </Typography>
                          </Box>
                          <Typography sx={{ fontSize: '12px', fontWeight: 500, lineHeight: '16px', color: '#7B7B7B' }}>
                            2 min
                          </Typography>
                        </Box>
                      </MenuItem>
                    </Card>
                  )
                })}
              </Box>
            </Menu>
            <IconButton onClick={handleDrawerOpen}>
              <MenuIcon sx={{ color: scroll ? '#0D1E32' : '#B5D9D7' }} fontSize='large' />
            </IconButton>
          </Box>
        </Box>
      </Toolbar>

      <DailogAuth ref={authRef} />

      <Drawer
        sx={{
          width: 250,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 250,
            backgroundColor: '#E3EDED'
          }
        }}
        variant='persistent'
        anchor='right'
        open={openDrawer}
      >
        <DrawerHeaderWrapper>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronLeftIcon sx={{ color: '#0D1E32' }} />
            ) : (
              <ChevronRightIcon sx={{ color: '#0D1E32' }} />
            )}
          </IconButton>
        </DrawerHeaderWrapper>
        <Divider />
        <List>
          {user ? (
            <>
              <ListItemButton onClick={handleClick} sx={{ justifyContent: 'space-between' }}>
                <Box display='flex' alignItems='center'>
                  <ListItemIcon>
                    <UserAvatar sx={{ width: 30, height: 30 }}>{userInitial}</UserAvatar>
                  </ListItemIcon>
                  <Box ml={2} sx={{ textAlign: 'center' }}>
                    <Typography variant='fm-p2' sx={{ color: '#0D1E32', textAlign: 'center' }}>
                      {userName}
                    </Typography>
                  </Box>
                </Box>
                {openDropdown ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openDropdown} timeout='auto' unmountOnExit>
                <List component='div' disablePadding sx={{ justifyContent: 'center' }}>
                  <ListItemButton onClick={handleLogout}>
                    <ListItemIcon sx={{ mr: 1, color: 'primary.contrastText' }}>
                      <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary='Logout' primaryTypographyProps={{ sx: { color: 'primary.contrastText' } }} />
                  </ListItemButton>
                </List>
              </Collapse>
              <Divider />
            </>
          ) : (
            <>
              <ListItemButton
                onClick={() => {
                  authRef?.current?.openLogin()
                }}
              >
                <ListItemIcon>
                  <InboxIcon sx={{ color: '#0D1E32' }} />
                </ListItemIcon>
                <ListItemText
                  primary={'Login'}
                  primaryTypographyProps={{ sx: { color: '#0D1E32', fontWeight: 700 } }}
                />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  authRef?.current?.openRegistartion()
                }}
              >
                <ListItemIcon>
                  <MailIcon sx={{ color: '#0D1E32' }} />
                </ListItemIcon>
                <ListItemText
                  primary={'Register'}
                  primaryTypographyProps={{ sx: { color: '#0D1E32', fontWeight: 700 } }}
                />
              </ListItemButton>
              <Divider />
            </>
          )}
          <ListItemButton onClick={() => handleNavigate('/landing-page')}>
            <ListItemIcon>
              <InboxIcon sx={{ color: '#0D1E32' }} />
            </ListItemIcon>
            <ListItemText primary={'Home'} primaryTypographyProps={{ sx: { color: '#0D1E32', fontWeight: 700 } }} />
          </ListItemButton>
          <ListItemButton onClick={handleClickBrowseCollapse}>
            <ListItemIcon>
              <InboxIcon sx={{ color: '#0D1E32' }} />
            </ListItemIcon>
            <ListItemText primary={'Browse'} primaryTypographyProps={{ sx: { color: '#0D1E32', fontWeight: 700 } }} />
            {openBrowseCollapse ? <ExpandLess sx={{ color: '#0D1E32' }} /> : <ExpandMore sx={{ color: '#0D1E32' }} />}
          </ListItemButton>
          <Collapse in={openBrowseCollapse} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              <ListItemButton sx={{ pl: 10 }}>
                <ListItemIcon>
                  <StarBorder sx={{ color: '#0D1E32' }} />
                </ListItemIcon>
                <ListItemText
                  primary='Top Boat Rental Cities'
                  primaryTypographyProps={{ sx: { color: '#0D1E32', fontWeight: 700 } }}
                />
              </ListItemButton>
              <ListItemButton sx={{ pl: 10 }}>
                <ListItemIcon>
                  <StarBorder sx={{ color: '#0D1E32' }} />
                </ListItemIcon>
                <ListItemText
                  primary='Lake Boat Rentals'
                  primaryTypographyProps={{ sx: { color: '#0D1E32', fontWeight: 700 } }}
                />
              </ListItemButton>
              <ListItemButton sx={{ pl: 10 }}>
                <ListItemIcon>
                  <StarBorder sx={{ color: '#0D1E32' }} />
                </ListItemIcon>
                <ListItemText
                  primary='Boat Rental Near Me'
                  primaryTypographyProps={{ sx: { color: '#0D1E32', fontWeight: 700 } }}
                />
              </ListItemButton>
            </List>
          </Collapse>
          <ListItemButton>
            <ListItemIcon>
              <InboxIcon sx={{ color: '#0D1E32' }} />
            </ListItemIcon>
            <ListItemText primary={'Help'} primaryTypographyProps={{ sx: { color: '#0D1E32', fontWeight: 700 } }} />
          </ListItemButton>
        </List>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleNavigate('/contact')}>
              <ListItemIcon>
                <InboxIcon sx={{ color: '#0D1E32' }} />
              </ListItemIcon>
              <ListItemText
                primary={'Contact Us'}
                primaryTypographyProps={{ sx: { color: '#0D1E32', fontWeight: 700 } }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleNavigate('/about')}>
              <ListItemIcon>
                <MailIcon sx={{ color: '#0D1E32' }} />
              </ListItemIcon>
              <ListItemText
                primary={'About Us'}
                primaryTypographyProps={{ sx: { color: '#0D1E32', fontWeight: 700 } }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </AppBarWrapper>
  )
}

export default PublicLayoutAppBar
