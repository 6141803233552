import React, { Suspense } from 'react'
import { Routes, Route, Outlet, Navigate } from 'react-router-dom'
import FallbackSpinner from './@core/components/spinner'
import { GoogleOAuthProvider } from '@react-oauth/google'

import AuthGuard from 'src/@core/components/auth/AuthGuard'
import UserLayout from './layouts/UserLayout'
import BlankLayout from './@core/layouts/BlankLayout'
// import BlankLayoutWithAppBar from './@core/layouts/BlankLayoutWithAppBar'
import AclGuard from './@core/components/auth/AclGuard'
import GuestGuard from './@core/components/auth/GuestGuard'
import { defaultACLObj } from './configs/acl'
import AuthLayout from './layouts/AuthLayout'
import PublicLayout from './layouts/PublicLayout'

// const HomePage = React.lazy(() => import('./pages/home'))
// const DashBoardPage = React.lazy(() => import('./pages/dashboard'))
const Page401 = React.lazy(() => import('./pages/401'))
const Page404 = React.lazy(() => import('./pages/404'))

// Cruz Page Imports
const LandingPage = React.lazy(() => import('./pages/landingPage'))
const AboutUs = React.lazy(() => import('./pages/aboutUs'))
const TermsAndConditionPage = React.lazy(() => import('./components/publicInformativePages/termsAndConditions'))
const PrivacyPolicy = React.lazy(() => import('./components/publicInformativePages/privacyPolicy'))
const CookiePolicy = React.lazy(() => import('./components/publicInformativePages/cookiePolicy'))
const CareerPage = React.lazy(() => import('./components/careerPage/career'))
const CareerDetailsPage = React.lazy(() => import('./components/careerPage/careerDetails'))
const ContactUs = React.lazy(() => import('./components/publicInformativePages/contactUs'))
const PaymentPage = React.lazy(() => import('./pages/payment'))
const BookingPage = React.lazy(() => import('./pages/booking'))

const DetailsPage = React.lazy(() => import('./pages/detailsPage'))

const Guard = ({ children, authGuard, guestGuard }) => {
  if (guestGuard) {
    return <GuestGuard fallback={<FallbackSpinner />}>{children}</GuestGuard>
  } else if (!guestGuard && !authGuard) {
    return <>{children}</>
  } else {
    return <AuthGuard fallback={<FallbackSpinner />}>{children}</AuthGuard>
  }
}

function App() {
  const aclAbilities = defaultACLObj

  return (
    <GoogleOAuthProvider clientId='77297453844-55p8pk3r6igggtdnm38vfebc1o67dk6f.apps.googleusercontent.com'>
      <Suspense fallback={<FallbackSpinner />}>
        <AclGuard aclAbilities={aclAbilities}>
          <Routes>
            <Route
              element={
                <BlankLayout>
                  <Outlet />
                </BlankLayout>
              }
            >
              <Route path='/401' element={<Page401 />} />
              <Route path='/404' element={<Page404 />} />

              <Route
                element={
                  <AuthLayout>
                    <Outlet />
                  </AuthLayout>
                }
              >
                <Route
                  element={
                    <Guard guestGuard>
                      <Outlet />
                    </Guard>
                  }
                >
                  {/* <Route path='/login' element={<LoginPage />}></Route> */}
                </Route>
              </Route>
            </Route>
            <Route
              element={
                <PublicLayout>
                  <Outlet />
                </PublicLayout>
              }
            >
              <Route
                element={
                  <Guard guestGuard>
                    <Outlet />
                  </Guard>
                }
              >
                <Route path='/' element={<Navigate to='/landing-page' replace />} />
                <Route path='/landing-page' element={<LandingPage />} />
                <Route path='/terms&conditions' element={<TermsAndConditionPage />} />
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route path='/cookie-policy' element={<CookiePolicy />} />{' '}
                <Route path='/career-page' element={<CareerPage />} />
                <Route path='/careerDetails-page' element={<CareerDetailsPage />} />
                <Route path='/contact' element={<ContactUs />} />
                <Route path='/about' element={<AboutUs />} />
                <Route path='/details-page/:id' element={<DetailsPage />} />
              </Route>
            </Route>
            <Route
              element={
                <PublicLayout>
                  <Outlet />
                </PublicLayout>
              }
            >
              <Route
                element={
                  <Guard authGuard>
                    <Outlet />
                  </Guard>
                }
              >
                {/* <Route path='' element={<HomePage />} />
              <Route path='/dashboard' element={<DashBoardPage />} */}
                <Route path='/payment' element={<PaymentPage />} />
                <Route path='/bookings' element={<BookingPage />} />
              </Route>
            </Route>

            {/* If no route found redirect it to --> /404 */}
            <Route path='*' element={<Navigate to='/404' replace />} />
          </Routes>
        </AclGuard>
      </Suspense>
    </GoogleOAuthProvider>
  )
}

export default App
